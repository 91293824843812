<template>
	<ListChapterSketelon />
</template>

<script>
import { mapGetters } from 'vuex';
import ListChapterSketelon from '@/components/skeletons/ListChapterSketelon.vue';

export default {
	name: 'TopicFormationDashBoardIndex',
	components: { ListChapterSketelon },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	mounted() {
		this.redirectLogic();
	},
	watch: {
		formationProgress() {
			this.redirectLogic();
		},
	},
	methods: {
		redirectLogic() {
			if (this.formationProgress == null) return;
			if (this.$route.query.directTo === 'parcours-detail') return this.directToFirstParcours();
			if (this.formationProgress.formation.last_category && this.formationProgress.formation.last_session_id)
				return this.directToChapterOfCurrentSession();
			this.directToFirstParcours();
		},
		directToFirstParcours() {
			const firstParcoursItem = [...this.formationProgress.config.parcours_list].sort(
				(a, b) => a.position - b.position
			)[0];

			this.$router.push({
				name: 'Topic Dashboard Parcours Detail',
				params: { parcoursId: firstParcoursItem.parcours_id._id },
			});
		},
		directToChapterOfCurrentSession() {
			const currentCategoryId = this.formationProgress.formation.last_category;
			const parcoursItemOfCurrentCategory = this.formationProgress.config.parcours_list.find((parcoursItem) => {
				return parcoursItem.parcours_id.categories.find((cat) => cat._id === currentCategoryId);
			});

			this.$router.push({
				name: 'Topic Dashboard Chapter Detail',
				params: { idParcours: parcoursItemOfCurrentCategory.parcours_id._id, idCategory: currentCategoryId },
			});
		},
	},
};
</script>
